import React, { useEffect, useState } from "react"
import { API_PAGES } from "../global/api"
import Loader from "../components/Loader/Loader"
import LayoutSubpage from "../layouts/layout_subpage"

function Temapte({ pageContext }) {
  const { id, title } = pageContext

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetch(`${API_PAGES}/${id}`)
        .then(response => {
          if (response.ok) {
            return response
          }
          throw Error(response.status)
        })
        .then(response => response.json())
        .then(data => {
          setData(data.site)
          setLoading(false)
        })
        .catch(error => console.log(error))
    }
    fetchData()
  }, [id])

  return (
    <LayoutSubpage title={title}>
      <>
        {loading ? (
          <Loader additionClass="c-Word__loader" />
        ) : (
          data && (
            <>
              <span className="label">{data.title}</span>
              <div
                className="richtext"
                dangerouslySetInnerHTML={{ __html: data.html }}
              />
            </>
          )
        )}
      </>
    </LayoutSubpage>
  )
}
export default Temapte
